<template>
    <v-card-text
        v-if="amITheWinner"
        class="mt-0 pt-0"
        style="display: flex; align-items: center; flex-flow: column wrap"
    >
        <span
            class="text-h6"
        ><strong>{{ $t('awards.winner.title') }}</strong></span>

        <span
            class="text-h5 winner-simple-text"
            style="color: #006ff5;"
        >{{ `${winner.name} ${winner.lastname}` }}</span>
        <span
            class="text-h6 winner-simple-text"
        >{{ $t('awards.winner.points', {'points': winner.points_total}) }}
        </span>

        <img
            class=" mt-3"
            src="@/assets/img/winners/hombre_contento.png"
            max-height="350px"
        >

        <span
            class="text-h7 mt-3 winner-simple-text"
            style="text-align: center;"
            v-html="$t('awards.winner.winnerArea', {'area': getArea.name})"
        />

        <span
            class="text-h7 mt-3 winner-simple-text"
        >
            Elige tu <a
                style="color: #006ff5; text-decoration: underline;"
                @click="$router.push('gifts')"
            >premio</a> y contacta con RRHH
        </span>

        <span
            class="mt-5"
            style="color: #006ff5; text-decoration: underline; cursor: pointer"
            @click="$router.push('awards/areas')"
        > {{ $t('awards.winner.seeWinnerAreas') }}</span>
    </v-card-text>
</template>

<script>
import listareasCompetition from '@/data/areasCompetition'
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'

export default {
    name: 'Winner',
    data: function () {
        return {
            areasCompetition: listareasCompetition,
            winner: null
        }
    },
    computed: {
        ...mapGetters(['getUserId', 'getMyGroups']),
        amITheWinner() {
            if (this.winner) {
                return this.winner.id === this.getUserId
            }

            return null
        },
        getArea() {
            let area = null;
            let index = null;
            if (this.getMyGroups.length) {
                const myGroups = this.getMyGroups[0];
                index = listareasCompetition.findIndex(a => a.id === +myGroups);
                area = listareasCompetition[index];
            }

            return {
                name: area?.name ?? '',
                number: index !== null ? index : '',
                id: area?.id ?? ''
            }
        }
    },
    created() {
        this.getWinnerFromMyArea()
    },
    methods: {
        async getWinnerFromMyArea() {
            this.$store.dispatch('loading/start');

            try {
                const res = await axios
                    .get('getWinnerFromMyArea');

                this.winner = res.data.result;

                if (!this.amITheWinner) this.$router.replace('awards/areas');
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }

            this.$store.dispatch('loading/end');
        }
    }
}
</script>

<style scoped>

@media (min-width: 700px) {
  .winner-simple-text {

  }
}
</style>

export default [

    {
        name: 'Innovación',
        id: 5
    },
    {
        name: 'RRHH',
        id: 6
    },
    {
        name: 'Proveedores',
        id: 9
    },
    {
        name: 'Medios',
        id: 10
    },
    {
        name: 'Financiero',
        id: 23
    },
    {
        name: 'Desarrollo de negocio',
        id: 7
    },
    {
        name: 'Asesoramiento especializado',
        id: 26
    },
    {
        name: 'Gestión de casos',
        id: 28
    },
    {
        name: 'Operaciones Iris Assistance',
        id: 31
    },
    {
        name: 'Primera línea',
        id: 29
    },
    {
        name: 'Reembolsos, Pagos y Fraude',
        id: 30
    },
    {
        name: 'Experiencia de cliente',
        id: 27
    },
    {
        name: 'Asistencia en viaje y Teleasistencia 1',
        id: 33
    },
    {
        name: 'Asistencia médica y Salud',
        id: 32
    },
    {
        name: 'Asistencia jurídica',
        id: 34
    }
]
